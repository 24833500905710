<template>
  <div>
    <member-breadcrumb :title="title">
      <template slot="right-part">
        <el-button @click="$router.back()" size="small">返回</el-button>
      </template>
    </member-breadcrumb>
    <project-form url="/project/update.do" :model="model" @success="success"></project-form>
  </div>
</template>

<script>
  import MemberBreadcrumb from '../common/MemberBreadcrumb'
  import ProjectForm from './ProjectForm'

  export default {
    name: 'ProjectUpdate',
    components: {ProjectForm, MemberBreadcrumb},
    data () {
      return {
        title: '编辑项目',
        model: {
          projectType: '',
          projectBudget: '',
          projectName: '',
          projectIntro: '',
          projectAttach: ''
        }
      }
    },
    beforeCreate () {
      const self = this
      self.$http.get({
        url: '/project/detail.do',
        data: {id: self.$route.params.id},
        success (res) {
          res.data.projectBudget /= 100
          res.data.projectType = res.data.projectType.toString()
          res.data.createdAt = res.data.updatedAt = ''
          self.model = res.data
        }
      })
    },
    methods: {
      success () {
        this.$message.success('修改成功')
        this.$router.back()
      }
    }
  }
</script>

<style scoped>

</style>